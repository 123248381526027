import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faSquare, faWarning } from '@fortawesome/free-solid-svg-icons'
import toast, { Toaster } from 'react-hot-toast';
import './ImageClassifier.css';

function ImageClassifier(props) {

    const [showInformationModal, setShowInformationModal] = useState(false);

    const [image, setImage] = useState({ src: '', alt: '' });
    const [isFetchingImage, setIsFetchingImage] = useState(false);
    const [noMoreImages, setNoMoreImages] = useState(false);

    const [classifiers, setClassifiers] = useState({});

    const [selectedClassificationArea, setSelectedClassificationArea] = useState(null);
    const [classificationAreas, setClassificationAreas] = useState([]);

    const [selectedClassifiersForImage, setselectedClassifiersForImage] = useState({});

    const [missingClassifications, setMissingClassifications] = useState({});
    const [missingClassificationsForAreas, setMissingClassificationsForAreas] = useState([]);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const startX = useRef(0);
    const startY = useRef(0);

    // Fetch the classifiers metadata from the server and store it in the state
    const retrieveClassifiers = () => {
        fetch(`${window.imageServerURL}/classifications`, {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(classifiers => {
                setClassifiers(classifiers);
            })
            .catch(error => {
                console.log(error);
            });
    };

    // Sets the selected classifier for the current image / classification area
    const setSelectedClassifier = (classifier, type) => {
        if (selectedClassificationArea !== null) {
            // If a classification area is selected, set the selected classifier for the classification area
            const newClassificationAreas = [...classificationAreas];
            const newClassificationArea = { ...newClassificationAreas[selectedClassificationArea] };

            // If the type is already selected, remove it. Otherwise, set it
            if (newClassificationArea.classifications[classifier] === type) {
                delete newClassificationArea.classifications[classifier];
            } else {
                newClassificationArea.classifications[classifier] = type;
            }

            // Update the classification area in the state
            newClassificationAreas[selectedClassificationArea] = { ...newClassificationArea };
            setClassificationAreas(newClassificationAreas);

            // Remove the missing area classification from the missingClassificationsForAreas as it is no longer missing
            const newMissingClassificationsForAreas = [...missingClassificationsForAreas];
            let newMissingClassificationsForArea = newMissingClassificationsForAreas[selectedClassificationArea];
            if (newMissingClassificationsForArea) {
                delete newMissingClassificationsForArea[classifier];
                newMissingClassificationsForAreas[selectedClassificationArea] = newMissingClassificationsForArea;
            }
            setMissingClassificationsForAreas(newMissingClassificationsForAreas);
        } else {
            // For image-level classification
            const newSelectedClassifiersForImage = { ...selectedClassifiersForImage };
            if (!newSelectedClassifiersForImage[classifier]) {
                newSelectedClassifiersForImage[classifier] = [];
            }
            const index = newSelectedClassifiersForImage[classifier].indexOf(type);
            if (index === -1) {
                if (classifiers.image.find(c => c.type === classifier).multiSelect) {
                    newSelectedClassifiersForImage[classifier].push(type);
                } else {
                    newSelectedClassifiersForImage[classifier] = [type];
                }
            } else {
                newSelectedClassifiersForImage[classifier].splice(index, 1);
            }
            setselectedClassifiersForImage({ ...newSelectedClassifiersForImage });

            // Remove the missing image classification from the state as it is no longer missing
            const newMissingClassifications = { ...missingClassifications };
            delete newMissingClassifications[classifier];
            setMissingClassifications(newMissingClassifications);
        }
    };

    const checkForRequiredClassifications = () => {
        // Check if any required classifiers are missing from the image
        let tempMissingClassifications = {};

        // Check for image-level required classifiers
        classifiers.image.forEach(classifier => {
            if (classifier.required) {
                if (!selectedClassifiersForImage[classifier.type] || selectedClassifiersForImage[classifier.type].length === 0) {
                    tempMissingClassifications[classifier.type] = true;
                }
            }
        });

        setMissingClassifications(tempMissingClassifications);

        // Check if any required classifiers are missing from the classification areas
        let tempMissingClassificationsForAreas = [];
        classificationAreas.forEach((classificationArea) => {
            let missingClassificationsForArea = {};
            Object.keys(classifiers.area).forEach(classifier => {
                if (classifiers.area[classifier].required && !classificationArea.classifications[classifiers.area[classifier].type]) {
                    missingClassificationsForArea = { ...missingClassificationsForArea, [classifiers.area[classifier].type]: true };
                }
            });
            tempMissingClassificationsForAreas.push(missingClassificationsForArea);
        });
        setMissingClassificationsForAreas(tempMissingClassificationsForAreas);

        // If there are missing classifications, return true
        if (Object.keys(tempMissingClassifications).length > 0 ||
            tempMissingClassificationsForAreas.some(missingClassificationsForArea =>
                Object.keys(missingClassificationsForArea).length > 0)) {
            return true;
        }

        // Otherwise, return false
        return false;
    };


    const classifyImage = () => {
        // If there are required classifications missing, do not classify the image
        if (checkForRequiredClassifications()) {
            // Display a toast message to the user to let them know that required classifications are missing
            toast.dismiss();
            toast.error('Please select all required classifications', {
                style: {
                    background: '#744',
                }
            });
            return;
        }

        // If there are no missing classifications, reset the missing classifications state
        setMissingClassifications({});
        setMissingClassificationsForAreas([]);

        // Include image dimensions in the classification
        const imageElem = document.querySelector('.mainImage');
        const imageDimensions = {
            width: imageElem.naturalWidth,
            height: imageElem.naturalHeight
        };

        // send the selected classifiers to the server along with the image file name
        fetch(`${window.imageServerURL}/classify`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fileName: image.alt,
                imageClassifications: selectedClassifiersForImage,
                areaClassifications: classificationAreas,
                imageDimensions: getImageDimensions(),
                flagged: false,
                user: props.user
            })
        })

        // Display a toast message to the user to let them know that the image has been classified
        toast.dismiss();
        toast.success('Image Classified, loading next image...');

        // load the next image
        loadNextImage();
    };

    const flagImage = () => {
        // Reset the missing classifications state
        setMissingClassifications({});
        setMissingClassificationsForAreas([]);

        // Flag the image as needing review
        fetch(`${window.imageServerURL}/classify`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fileName: image.alt,
                imageClassifications: selectedClassifiersForImage,
                areaClassifications: classificationAreas,
                imageDimensions: getImageDimensions(),
                flagged: true,
                user: props.user
            })
        })

        // Display a toast message to the user to let them know that the image has been flagged as irrelevant
        toast.dismiss();
        toast('Image marked as irrelevant, loading next image...', {
            style: {
                background: '#774',
            }
        });

        // load the next image
        loadNextImage();
    };

    const skipImage = () => {
        // Reset the missing classifications state
        setMissingClassifications({});
        setMissingClassificationsForAreas([]);

        // Display a toast message to the user to let them know that the image has been skipped
        toast.dismiss();
        toast('Image skipped, loading next image...', {
            style: {
                background: '#774',
            }
        });

        // load the next image
        loadNextImage();
    };

    const loadNextImage = () => {
        // Fetch a new image from the server
        setIsFetchingImage(true);

        // Reset the selected classification area
        setSelectedClassificationArea(null);

        // Fetch a new image from the server
        setTimeout(() => {
            fetch(`${window.imageServerURL}/random-image`, {
                credentials: 'include'
            })
                .then(response => {
                    if (response.status !== 404) {
                        // If the response is not 404, return the response as json
                        return response.json();
                    } else {
                        // If the response is 404, no more images to classify
                        throw new Error('No more images to classify');
                    }
                })
                .then(({ fileName, data }) => {
                    // Wait before updating the image fetching state
                    setTimeout(() => {
                        setIsFetchingImage(false);
                    }, 250);
                    // Set the new image in the state
                    const src = `data:image/jpeg;base64,${data}`;
                    setImage({ src, alt: fileName });
                })
                .catch(error => {
                    console.error(error);
                    setIsFetchingImage(false);
                    if (error.message === 'No more images to classify') {
                        setNoMoreImages(true);
                    }
                });
        }, 250);

        // Reset the selected classifiers
        setselectedClassifiersForImage({});

        // Reset the classification areas
        setClassificationAreas([]);

        // Smooth scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const getImageDimensions = () => {
        // Get the dimensions of the current image
        const imageElem = document.querySelector('.mainImage');
        const imageDimensions = {
            width: imageElem.naturalWidth,
            height: imageElem.naturalHeight
        };
        return imageDimensions;
    };

    // Handle the mouse down event on the image / classification area
    const imgMouseDownHandler = (event) => {
        if (classifiers.options.areaClassificaitonEnabled) {
            if (classifiers.area.length !== 0) {
                const selection = document.querySelector('.selection');
                const selectionContainer = document.querySelector('.selectionContainer');

                setIsMouseDown(true);
                startX.current = event.pageX - selectionContainer.offsetLeft;
                startY.current = event.pageY - selectionContainer.offsetTop;
                selection.style.left = startX.current + 'px';
                selection.style.top = startY.current + 'px';
                selection.style.width = 0;
                selection.style.height = 0;
            }
        }
    };

    // Handle the mouse move event on the image / classification area
    const imgMouseMoveHandler = (event) => {
        if (classifiers.options.areaClassificaitonEnabled) {
            const selection = document.querySelector('.selection');
            const selectionContainer = document.querySelector('.selectionContainer');

            if (isMouseDown) {

                // Check if mouse has moved enough to mark as a drag
                if (!isDragging) {
                    const xDiff = Math.abs(event.pageX - selectionContainer.offsetLeft - startX.current);
                    const yDiff = Math.abs(event.pageY - selectionContainer.offsetTop - startY.current);
                    if (xDiff > 5 || yDiff > 5) {
                        setIsDragging(true);
                    }
                }

                const width = event.pageX - selectionContainer.offsetLeft - startX.current;
                const height = event.pageY - selectionContainer.offsetTop - startY.current;
                selection.style.width = Math.abs(width) + 'px';
                selection.style.height = Math.abs(height) + 'px';
                if (width < 0) {

                    // If the width is negative, move the selection to the left
                    selection.style.left = event.pageX - selectionContainer.offsetLeft + 'px';
                }
                if (height < 0) {

                    // If the height is negative, move the selection up
                    selection.style.top = event.pageY - selectionContainer.offsetTop + 'px';
                }
            }
        }
    };

    // Handle the mouse up event on the image / classification area
    const imgMouseUpHandler = (hoveredAreaIndex) => {
        if (classifiers.options.areaClassificaitonEnabled) {
            const image = document.querySelector('.mainImage');
            const selection = document.querySelector('.selection');

            // If the mouse is not dragging a new area, toggle the hovered classification area
            if (!isDragging) {
                // If the hovered area is the same as the selected area, deselect it
                if (selectedClassificationArea === hoveredAreaIndex) {
                    setSelectedClassificationArea(null);
                } else {
                    setSelectedClassificationArea(hoveredAreaIndex);
                }
            }

            setIsMouseDown(false);

            if (isDragging) {
                setIsDragging(false);
            }

            // Get the selection and image bounding rectangles
            const { left, top, width, height } = selection.getBoundingClientRect();
            const { x, y, width: imageWidth, height: imageHeight } = image.getBoundingClientRect();

            // Calculate the area of the selection as a percentage of the source image (0 - 1)
            // This is used to display the selection on the image
            const area = {
                x: (left - x) / imageWidth,
                y: (top - y) / imageHeight,
                width: width / imageWidth,
                height: height / imageHeight
            };

            // Calculate the area of the selection in pixels of the source image
            // This is used for training the classifier
            const areaInPixels = {
                x: Math.round(area.x * image.naturalWidth),
                y: Math.round(area.y * image.naturalHeight),
                width: Math.round(area.width * image.naturalWidth),
                height: Math.round(area.height * image.naturalHeight)
            };

            // Create a new classification area
            const newClassificationArea = {
                area,
                areaInPixels,
                classifications: {}
            };

            // Add the new classification area to the list of classification areas
            if (area.width > 0.1 && area.height > 0.05) {
                setClassificationAreas(prevClassificationAreas => [...prevClassificationAreas, newClassificationArea]);
                setSelectedClassificationArea(classificationAreas.length);
            }

            // Reset the selection
            selection.style.width = 0;
            selection.style.height = 0;
        }
    };


    // remove the selected classification area
    const removeClassificationArea = (indexToDelete) => {

        // Select the previous classification area unless the selected classification area is the first one
        if (selectedClassificationArea === 0 && classificationAreas.length === 1) {
            setSelectedClassificationArea(null);
        } else if (selectedClassificationArea >= indexToDelete && selectedClassificationArea > 0) {
            setSelectedClassificationArea(prevSelectedClassificationArea => prevSelectedClassificationArea - 1);
        }

        // Remove the classification area from the list of classification areas
        setClassificationAreas(prevClassificationAreas => {
            const newClassificationAreas = [...prevClassificationAreas];
            newClassificationAreas.splice(indexToDelete, 1);
            return newClassificationAreas;
        });

        // Shift the missing classifications for areas after the deleted area down by one
        setMissingClassificationsForAreas(prevMissingClassificationsForAreas => {
            const newMissingClassificationsForAreas = [...prevMissingClassificationsForAreas];
            newMissingClassificationsForAreas.splice(indexToDelete, 1);
            return newMissingClassificationsForAreas;
        });
    };

    // Get a pastel color for the given index
    const getPastelColorForIndex = (index) => {
        const pastelColors = [
            '#FFB34760',
            '#C23B2260',
            '#F7CAC960',
            '#92A8D160',
            '#97D38C60',
            '#F7786B60',
            '#D6CE2260',
            '#4ECDC460'
        ];
        return pastelColors[index % pastelColors.length];
    };

    // Retrieve the classifiers metadata from the server when the component is mounted
    useEffect(() => {
        retrieveClassifiers();
    }, []);

    return (
        <div className="imageClassifierContainer">
            <Toaster
                toastOptions={{
                    style: {
                        background: '#474',
                        color: '#fff',
                        fontSize: '1.2rem'
                    }
                }}
            />
            <div className={"informationModalContainer" + (showInformationModal ? '' : ' hidden')} onClick={() => setShowInformationModal(false)}>
                <div className="informationModal">
                    <h1>Instructions</h1>
                    <h2>Image Classification</h2>
                    <h3>Mandatory Classifications</h3>
                    <p>Classifications marked with an asterisk ( * ) are mandatory and must be selected before submitting the image.</p>
                    <h3>Multi-Select Classifications</h3>
                    <p>Classifications marked with a plus ( ⁺ ) allow for multiple selections on a single classifier, options can be toggled on and off by clicking / tapping on them.</p>
                    <br />
                    <h2>Image Submission Buttons</h2>
                    <h3>Mark as Irrelevant</h3>
                    <p>If the image shown is not relevant to the classification task (i.e. it does not contain a damaged asset), click this button to mark it as irrelevant and move to the next image.</p>
                    <h3>Skip Image</h3>
                    <p>If you are unable to classify the image for any reason, but think it may still be a useful image; click this button to skip the image and move to the next one.</p>
                    <h3>Classify Image</h3>
                    <p>If you have selected all the required classifications, click this button to submit the image for classification and move to the next one.</p>
                </div>
            </div>
            <div className="imageContainer">
                <div className="logOutContainer">
                    <div className="userName">{props.user}</div>
                    <button className="logOutButton" onClick={props.logout}>Log Out</button>
                </div>
                <div className="selectionContainer">
                    <img className={"mainImage" + (isFetchingImage ? ' loading' : '') + (selectedClassificationArea == null ? ' selected' : '')}
                        src={image.src}
                        alt={image.alt}
                        draggable={false}
                        onMouseDown={imgMouseDownHandler}
                        onMouseMove={imgMouseMoveHandler}
                        onMouseUp={() => imgMouseUpHandler(null)}
                    />
                    <div className="selection"></div>
                    {classificationAreas.map((classificationArea, index) => (
                        <div className={"classificationArea" + (selectedClassificationArea === index ? ' selected' : selectedClassificationArea === null ? '' : ' backgrounded')}
                            onMouseDown={imgMouseDownHandler}
                            onMouseMove={imgMouseMoveHandler}
                            onMouseUp={() => imgMouseUpHandler(index)}
                            key={index}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                removeClassificationArea(index);
                            }}
                            style={{
                                backgroundColor: getPastelColorForIndex(index),
                                left: `${classificationArea.area.x * 100}%`,
                                top: `${classificationArea.area.y * 100}%`,
                                width: `${classificationArea.area.width * 100}%`,
                                height: `${classificationArea.area.height * 100}%`,
                            }}>
                            <div className="removeAreaButton"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeClassificationArea(index);
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseMove={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}>
                                &times;
                            </div>
                            <span>{index + 1}</span>
                        </div>
                    ))}
                </div>
                <div className={"instructionsContainer" + (image.src == '' || noMoreImages || isFetchingImage ? ' hidden' : '')}>
                    <span>Use the options below to classify the image.</span>
                </div>
            </div>
            <div className="classifierContainer">
                {image.src !== '' && !noMoreImages ?
                    <>
                        <div className="classifierAreaTabs">
                            <button
                                className={`classifierAreaTab ${selectedClassificationArea === null ? 'selected' : ''} ${Object.keys(missingClassifications).length > 0 ? 'required' : ''}`}
                                onClick={() => { setSelectedClassificationArea(null) }} >
                                {Object.keys(missingClassifications).length == 0 ?
                                    <FontAwesomeIcon icon={faImage} />
                                    :
                                    <FontAwesomeIcon icon={faWarning} />
                                }
                                Entire Image
                            </button>
                            {classificationAreas.map((area, index) => (
                                <button
                                    key={index}
                                    className={`classifierAreaTab ${selectedClassificationArea === index ?
                                        'selected' : ''
                                        } ${missingClassificationsForAreas[index] && Object.keys(missingClassificationsForAreas[index]).length ?
                                            'required' : ''
                                        }`}
                                    style={{ backgroundColor: getPastelColorForIndex(index) }}
                                    onClick={() => setSelectedClassificationArea(index)}
                                    onContextMenu={(event) => {
                                        event.preventDefault();
                                        removeClassificationArea(index);
                                    }}>
                                    {missingClassificationsForAreas[index] && Object.keys(missingClassificationsForAreas[index]).length !== 0 ?
                                        <FontAwesomeIcon icon={faWarning} />
                                        :
                                        <FontAwesomeIcon icon={faSquare} />
                                    }
                                    {"Area " + (index + 1)}
                                </button>
                            ))}
                        </div>
                        <div className="classifierSections">
                            {selectedClassificationArea === null ?
                                classifiers.image.map(classifier => (
                                    <ClassifierSection
                                        key={classifier.type}
                                        classifier={classifier}
                                        missing={missingClassifications[classifier.type]}
                                        selectedClassifier={selectedClassifiersForImage[classifier.type]}
                                        setSelectedClassifier={setSelectedClassifier}
                                    />
                                ))
                                :
                                classifiers.area.map(classifier => (
                                    <ClassifierSection
                                        key={classifier.type}
                                        classifier={classifier}
                                        missing={missingClassificationsForAreas[selectedClassificationArea]?.[classifier.type]}
                                        selectedClassifier={classificationAreas?.[selectedClassificationArea]?.classifications[classifier.type]}
                                        setSelectedClassifier={setSelectedClassifier}
                                    />
                                ))
                            }
                        </div>
                        <div className="classifierControls">
                            <button className="classifierButton danger" onClick={flagImage} disabled={isFetchingImage}>Mark as Irrelevant</button>
                            <button className="classifierButton warning" onClick={skipImage} disabled={isFetchingImage}>Skip Image</button>
                            <button className="classifierButton success" onClick={classifyImage} disabled={isFetchingImage}>Classify image</button>
                        </div>
                        <div className="classifierControls">
                            <button className="classifierButton info" onClick={() => setShowInformationModal(!showInformationModal)}>Help</button>
                        </div>
                    </>
                    :
                    <>
                        {noMoreImages ?
                            <>
                                <h2>All images classified!</h2>
                                <button className="classifierButton" onClick={props.logout}>Log Out</button>
                            </>
                            :
                            <>
                                <button className="classifierButton" onClick={loadNextImage}>Load First Image</button>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}

function ClassifierSection({ classifier, missing, selectedClassifier, setSelectedClassifier }) {
    return (
        <div className={`classifierSection ${missing ? 'required' : ''}`}>
            <span>{classifier.required ? '*' : ''}{classifier.multiSelect ? '⁺' : ''} {classifier.question}</span>
            <div className="classifierButtonContainer">
                {classifier.values.map(value => (
                    <button
                        key={value.value}
                        className={`classifierButton ${selectedClassifier && selectedClassifier.includes(value.value) ? 'selected' : ''}`}
                        onClick={() => setSelectedClassifier(classifier.type, value.value)}>
                        {value.name}
                    </button>
                ))}
            </div>
        </div>
    );
}


export default ImageClassifier;
