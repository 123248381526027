import './Login.css';
import { useState } from 'react';

function Login(props) {

  const [enteredEmail, setEnteredEmail] = useState('');

  const handleEmailChange = (e) => {
    setEnteredEmail(e.target.value.toLowerCase());
  };

  return (
    <div className="loginContainer">
      <div className="loginForm">
        <h1>Storm AI Image Classifier</h1>
        <p className="error">{props.error ? 'Invalid email address' : ''}</p>
        <input type="email" placeholder="Enter your company email..." value={enteredEmail}
          className={props.error ? 'error' : ''}
          onChange={handleEmailChange}
          onKeyUp={(e) => e.key === 'Enter' ? props.login(enteredEmail) : null}
        />
        <button onClick={() => props.login(enteredEmail)}>Login</button>
      </div>
    </div>
  );
}

export default Login;