import './App.css';
import Login from './Login';
import ImageClassifier from './ImageClassifier';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useEffect } from 'react';

// Set the image server URL globally
window.imageServerURL = '';

function App() {

  // The user state is used to determine if the user is logged in or not
  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState(false);

  // Check if the user is logged in from previous session
  useEffect(() => {
    if (!user) {
      const userEmail = Cookies.get('userEmail');
      if (userEmail) {
        setUser(userEmail);
      }
    }
  }, [])


  // log in to the application
  const login = (email) => {
    fetch(`${window.imageServerURL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    }).then(res => {
      if (res.ok) {
        setLoginError(false);
        return res.json();
      } else {
        setLoginError(true);
        throw new Error('Something went wrong!');
      }
    }).then(data => {
      // Set the user in the App component
      setUser(email);
      // Set the auth token and user email in the cookies
      Cookies.set('authToken', data.token);
      Cookies.set('userEmail', email);
    }).catch(err => {
      setLoginError(true);
      console.log(err);
    });
  }

  // log out of the application
  const logout = () => {
    // Remove the user from the App component
    setUser(null);
    // Remove the user's email and auth token from the cookies
    Cookies.remove('userEmail');
    Cookies.remove('authToken');
  };

  return (
    <div className="app">
      {user ?
        <ImageClassifier user={user} logout={logout} />
        :
        <Login login={login} error={loginError} />
      }
    </div>
  );
}

export default App
